import Before from './components/before'
import FooterContent from './components/footer'
import After from './components/after'

const Footer = () => {
  return (
    <footer>
      <Before />
      <FooterContent />
      <After />
    </footer>
  )
}

export default Footer
