import styled from 'styled-components'
import { Link } from 'preact-router/match'

const Nav = styled.nav`
  width: 100%;
  padding: 24px;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: #fff;

  a {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: rgba(255,255,255,0);
    text-decoration: none;
    color: #424242;
    text-align: center;
  }

  .main {
    font-weight: 500;
  }
`

const MobileHeader = ({ headerRef, ...props }) => {
  return (
    <Nav ref={headerRef}>
      {/* <Link href='/'>Sobre a Donamaid</Link> */}
      <Link onClick={props.toogleMenu} href='/'>Início</Link>
      <Link onClick={props.toogleMenu} href='/servicos'>Serviços</Link>
      <a onClick={props.toogleMenu} href='https://blog.donamaid.com'>Blog</a>
      <a onClick={props.toogleMenu} href='/servicos/assinatura'>Assinatura</a>
      <a onClick={props.toogleMenu} href='/seja-diarista-com-a-donamaid'>Quero ser diarista</a>
      <a onClick={props.toogleMenu} href='https://cliente.donamaid.com/cadastre-se'>Cadastre-se</a>
      <a onClick={props.toogleMenu} className="main" href='https://cliente.donamaid.com'>Entrar</a>
    </Nav>
  )
}

export default MobileHeader
