import { useState, useCallback } from 'preact/hooks'
import { Link } from 'preact-router/match'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import clickOutside from '../../hooks/clickOutside'

import MobileHeader from './components/mobileHeader'
import DesktopHeader from './components/desktopHeader'

const Root = styled.header`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 56px;
	padding: 0;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
`

const Content = styled.div`
	width: 100%;
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding: 0 24px;

	.logo {
		float: left;
		width: 125px;
		height: 100%;
		margin: auto 0;

		@media (min-width: 340px) {
			width: 150px;
		}

		@media (min-width: 768px) {
			width: 200px;
		}
	}

	.hamburger {
		display: flex;
		align-items: center;
		float: right;
		height: 100%;

		> img {
			height: 100%;
			width: 40px;
		}

		.login {
			padding: 6px 16px;
			margin-right: 16px;
			color: #424242;
			border: solid 2px #424242;
			border-radius: 4px;
			text-decoration: none;
			font-weight: 500;
		}
		
		@media (min-width: 1024px) {
			display: none;
		}
	}

	.background {
		height: 100vh;
		width: calc(100% + 48px);
		margin-left: -24px;
		z-index: 2;
		overflow: hidden;
		background: #424242;
		opacity: 0.5;
	}

	@media (min-width: 1024px) {
		width: calc(100% - 48px);
	}
`

const Header = () => {
	const [open, setOpen] = useState(false)
	const toogle =  useCallback(() => setOpen(!open), [open])

	const ref = clickOutside(toogle)

	return (
		<Root>
			<Content>
				<Link href='/'>
					<LazyLoadImage
            className='logo'
            src='../../assets/logos/logo.svg'
            alt='logo header Donamaid'
            title='logo header Donamaid'
          />
				</Link>
				
				<div className='hamburger' onClick={toogle}>
					<a className="login" href='https://cliente.donamaid.com'>Entrar</a>

					<LazyLoadImage
            src='../../assets/icons/hamburger.svg'
            alt="menu mobile"
          />
				</div>

				{ open && <MobileHeader headerRef={ref} toogleMenu={toogle} /> }
				{ open && <div className='background' /> }

				<DesktopHeader />
			</Content>
		</Root>
	)
}

export default Header
