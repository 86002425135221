import {  useRef, useEffect } from "preact/hooks"

function clickOutside(callback) {
  const callbackRef = useRef()
  const ref = useRef()
  
  useEffect(() => { callbackRef.current = callback })
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackRef.current()
      }
    }
  
    document.addEventListener('click', handleClickOutside)
    
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return ref
}

export default clickOutside
