import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  width: 100%;
  padding: 40px 24px;

  @media(min-width: 1024px) {
    max-width: 100%;
    ${({ fullWidth }) => !fullWidth && css`
      max-width: 1024px;
      margin: 0 auto;
    `};
  }
`

export default Section
