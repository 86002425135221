import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Section from '../../sections'

const BeforeSection = styled(Section)`
  background-color: #532D77;
`

const ContentSection = styled(Section)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
    height: 100%;
  }
  div {
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin-top: 16px;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-left: 16px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Before = () => {
  return (
    <BeforeSection fullWidth>
      <ContentSection>
        <LazyLoadImage
          className='logo' src='../../assets/logos/logo-white.svg'
          alt='logo footer Donamaid'
          title='logo footer Donamaid'
        />

        <div>
          <b>DONAMAID TECNOLOGIA LTDA</b> - 28.052.325/0001-37
        </div>
      </ContentSection>
    </BeforeSection>
  )
}

export default Before
