import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Root = styled.div`
  position: relative;
  height: ${({ compact }) => compact ? '32px' : '48px'};
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 4px;

  .right {
    position: absolute;
    top: ${({ compact }) => compact ? '4px' : '11px'};
    left: 8px;
  }

  .left {
    position: absolute;
    top: ${({ compact }) => compact ? '4px' : '11px'};
    right: 12px;
  }

  select {
    height: ${({ compact }) => compact ? '32px' : '48px'};
    width: 100%;
    max-width: 100%;
    line-height: ${({ compact }) => compact ? '28px' : '44px'};
    font-size: ${({ compact }) => compact ? '14px' : '16px'};;
    padding: 0 16px 0 ${({ adornment}) => adornment ? '36px' : '16px'};
    border-radius: 4px;
    border: solid 1px #e2e2e2;
    background-color: #fff;
    color: #424242;
    cursor: pointer;
    appearance: none;
  
    option {
      cursor: pointer;
    }
  }
`

const Select = ({ compact = false, adornmentImgPath = null, placeholder = null, selected = null, ...props }) => {
  function selectOptions() {
    return props.options.map(option => {
      if (typeof option === 'string') {
        return <option value={option} selected={selected === option}>{ option }</option>
      }

      return <option value={option.value} selected={selected === option.value}>{ option.label }</option>
    })
  }

  return (
    <Root adornment={Boolean(adornmentImgPath)} compact={compact}>
      { adornmentImgPath && <LazyLoadImage className='right' alt='prefix icon' src={`../../assets${adornmentImgPath}`} /> }

      <select size='1' onChange={props.onChange} value={selected} required={props.required}>
        { placeholder && <option>{ placeholder }</option> }

        { selectOptions() }
      </select>

      <LazyLoadImage className='left' alt='dropdown icon' src={`../../assets/icons/arrow_down.svg`} />
    </Root>
  )
}

export default Select
