import { Router } from 'preact-router'

import { DonamaidContextProvider } from '../context/DonamaidContext'

import Header from './header'
import Footer from './footer'

// Code-splitting is automated for `routes` directory
import Home from '../routes/home'
import Simulation from '../routes/simulation'
import Services from '../routes/services'
import City from '../routes/city'
// import Covid from '../routes/covid'
import TermsOfUse from '../routes/terms_of_use'
import PrivacyPolicy from '../routes/privacy_policy'
import Subscription from '../routes/subscription'
import Professional from '../routes/professionals'

import LPOne from '../routes/lpone'
import LPTwo from '../routes/lptwo'

const App = () => {
  function handleRoute (e) {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
      gtag('event', 'pageview', { page_location: e.url })
    }
  }

	return (
		<div id="app">
			<DonamaidContextProvider>
				{typeof window !== 'undefined' && !window.location.pathname.includes('/lp/') ? <Header /> : <></>}

        <Router onChange={handleRoute}>
          <Home path="/" />
          <Simulation path="/simule-sua-limpeza" />
          <Professional path="seja-diarista-com-a-donamaid" />
          <Services path="/servicos" />
          <Subscription path='/servicos/assinatura' />
          <City path="/cidades/:city" />
          {/* <Covid path='/atualizacoes-sobre-o-coronavirus' /> */}
          <TermsOfUse path='/termos-de-uso' />
          <PrivacyPolicy path='/politica-de-privacidade' />
          <LPOne path='/lp/mais_tempo_para_evoluir_sua_carreira' />
          <LPTwo path='/lp/mais_tempo_livre_para_maes' />
        </Router>

				{typeof window !== 'undefined' && !window.location.pathname.includes('/lp/') ? <Footer /> : <></>}
			</DonamaidContextProvider>
		</div>
	)
}

export default App
