import styled from 'styled-components'
import { Link } from 'preact-router/match'

const Nav = styled.nav`
  display: none;
  float: right;
	font-size: 100%;

  a {
    display: inline-block;
    height: 56px;
    line-height: 56px;
    text-align: center;
    background: rgba(255,255,255,0);
    text-decoration: none;
    color: #424242;

    :not(:last-child) {
      margin-right: 24px;
    }
  }
  
  .main {
    height: inherit;
    line-height: inherit;
    padding: 6px 16px;
    margin-right: 16px;
    color: #424242;
    border: solid 2px #424242;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

const DesktopHeader = () => {
  return (
    <Nav>
      {/* <Link href='/'>Sobre a Donamaid</Link> */}
      <Link href='/servicos'>Serviços</Link>
      <a href='https://blog.donamaid.com'>Blog</a>
      <a href='/servicos/assinatura'>Assinatura</a>
      <a href='/seja-diarista-com-a-donamaid'>Quero ser diarista</a>
      <a href='https://cliente.donamaid.com/cadastre-se'>Cadastre-se</a>
      <a className="main" href='https://cliente.donamaid.com'>Entrar</a>
    </Nav>
  )
}

export default DesktopHeader
